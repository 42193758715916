import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getListOfValuesByGroup} from "../../services/api/requests";
import {GROUP} from "../../utils/constants/back-office-constants";

export const fetchListOfValues = createAsyncThunk(
  "listOfValues/updateAsync",
  async (type, { getState }) => {
  
    const { numberOfCallsByState } = getState().listOfValues;

    //retrieve values only if there is not already a request to retrieve list of values for the same group ongoing
    if (numberOfCallsByState[type] && numberOfCallsByState[type] >1) {
      return;
    }
    const response = await getListOfValuesByGroup(type);
        return response;
  }
);

export const fetchListOfValuesForceRetrieve = createAsyncThunk(
  "listOfValues/updateAsync",
  async (type) => {
    const response = await getListOfValuesByGroup(type);
    return response;
  }
);

export const listOfValuesSlice = createSlice({
  name: "listOfValues",
  initialState: {
    universities: [],
    languages: [],
    courseTypes: [],
    courseSubTypes: [],
    courseCycles: [],
    courseTitles: [],
    periods: [],
    teachers: [],
    topics: [],
    sessionFormats: [],
    linksTypes: [],
    titles: [],
    opportunityTypes: [],
    userRoleGroupName: [],
    roleGroups: [],
    keywords: [],
    roles: [],
    allUniversities: [],
    allUniversitiesWithAcademic: [],
    visibleTeachers: [],
    logo:[],
    numberOfCallsByState: {},
    researchCenterUniversity:[],
    researchCenterUniversityWithAcademic:[],
    universityUnits: [],
    expertiseField: [],
    expertiseFieldWithAcademic: [],
    academicDisciplines: [],
    disciplineFieldWithAcademic: [],
    countriesResearchFieldWithAcademic: [],
    workingLanguageFieldWithAcademic: [],
    academicTitles: [],
    researchProjects: [],
    researchCountries: [],
    workingLanguages: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListOfValues.pending, (state, action) => {
        const currentType = action.meta.arg;
        if (!state.numberOfCallsByState[currentType]) {
          state.numberOfCallsByState[currentType] = 1;
        } else {
          state.numberOfCallsByState[currentType] = state.numberOfCallsByState[currentType]+1;
        }
      })
      .addCase(fetchListOfValues.fulfilled, (state, action) => {
         if (action.payload ) {
          // update state value depending on the group
                    GROUP_STATE[action.meta.arg].initialState(state, action.payload);
        }
      })
  },
});

export default listOfValuesSlice.reducer;

export const GROUP_STATE = {
  [GROUP.university]: {
    state: (state) => state.listOfValues.universities,
    initialState: (state, value) => (state.universities = value),
  },
  [GROUP.universityUnit]: {
    state: (state) => state.listOfValues.universityUnits,
    initialState: (state, value) => (state.universityUnits = value),
  },
  [GROUP.courseType]: {
    state: (state) => state.listOfValues.courseTypes,
    initialState: (state, value) => (state.courseTypes = value),
  },
  [GROUP.courseSubtype]: {
    state: (state) => state.listOfValues.courseSubTypes,
    initialState: (state, value) => (state.courseSubTypes = value),
  },
  [GROUP.courseCycle]: {
    state: (state) => state.listOfValues.courseCycles,
    initialState: (state, value) => (state.courseCycles = value),
  },
  [GROUP.courseTitle]: {
    state: (state) => state.listOfValues.courseTitles,
    initialState: (state, value) => (state.courseTitles = value),
  },
  [GROUP.language]: {
    state: (state) => state.listOfValues.languages,
    initialState: (state, value) => (state.languages = value),
  },
  [GROUP.period]: {
    state: (state) => state.listOfValues.periods,
    initialState: (state, value) => (state.periods = value),
  },
  [GROUP.teacher]: {
    state: (state) => state.listOfValues.teachers,
    initialState: (state, value) => (state.teachers = value),
  },
  [GROUP.topic]: {
    state: (state) => state.listOfValues.topics,
    initialState: (state, value) => (state.topics = value),
  },
  [GROUP.sessionFormat]: {
    state: (state) => state.listOfValues.sessionFormats,
    initialState: (state, value) => (state.sessionFormats = value),
  },
  [GROUP.linksType]: {
    state: (state) => state.listOfValues.linksTypes,
    initialState: (state, value) => (state.linksTypes = value),
  },
  [GROUP.title]: {
    state: (state) => state.listOfValues.titles,
    initialState: (state, value) => (state.titles = value),
  },
  [GROUP.opportunityType]: {
    state: (state) => state.listOfValues.opportunityTypes,
    initialState: (state, value) => (state.opportunityTypes = value),
  },
  [GROUP.userRoleGroupName]: {
    state: (state) => state.listOfValues.userRoleGroupName,
    initialState: (state, value) => (state.userRoleGroupName = value),
  },
  [GROUP.roleGroup]: {
    state: (state) => state.listOfValues.roleGroups,
    initialState: (state, value) => (state.roleGroups = value),
  },
  [GROUP.role]: {
    state: (state) => state.listOfValues.roles,
    initialState: (state, value) => (state.roles = value),
  },
  [GROUP.keyword]: {
    state: (state) => state.listOfValues.keywords,
    initialState: (state, value) => (state.keywords = value),
  },
  [GROUP.universityAll]: {
    state: (state) => state.listOfValues.allUniversities,
    initialState: (state, value) => (state.allUniversities = value),
  },
  [GROUP.universityAllWithAcademic]: {
    state: (state) => state.listOfValues.allUniversitiesWithAcademic,
    initialState: (state, value) => (state.allUniversitiesWithAcademic = value),
  },
  [GROUP.teacherVisible]: {
    state: (state) => state.listOfValues.visibleTeachers,
    initialState: (state, value) => (state.visibleTeachers = value),
  },
  [GROUP.logo]: {
    state: (state) => state.listOfValues.logo,
    initialState: (state, value) => (state.logo = value),
  },
  [GROUP.researchCenterUniversity]: {
    state: (state) => state.listOfValues.researchCenterUniversity,
    initialState: (state, value) => (state.researchCenterUniversity = value),
  },  
  [GROUP.researchCenterUniversityWithAcademic]: {
    state: (state) => state.listOfValues.researchCenterUniversityWithAcademic,
    initialState: (state, value) => (state.researchCenterUniversityWithAcademic = value),
  },  
  [GROUP.expertiseField]: {
    state: (state) => state.listOfValues.expertiseField,
    initialState: (state, value) => (state.expertiseField = value),
  },
  [GROUP.expertiseFieldWithAcademic]: {
    state: (state) => state.listOfValues.expertiseFieldWithAcademic,
    initialState: (state, value) => (state.expertiseFieldWithAcademic = value),
  },
  [GROUP.disciplineFieldWithAcademic]: {
    state: (state) => state.listOfValues.disciplineFieldWithAcademic,
    initialState: (state, value) => (state.disciplineFieldWithAcademic = value),
  },
  [GROUP.countriesResearchFieldWithAcademic]: {
    state: (state) => state.listOfValues.countriesResearchFieldWithAcademic,
    initialState: (state, value) => (state.countriesResearchFieldWithAcademic = value),
  },
  [GROUP.workingLanguageFieldWithAcademic]: {
    state: (state) => state.listOfValues.workingLanguageFieldWithAcademic,
    initialState: (state, value) => (state.workingLanguageFieldWithAcademic = value),
  },
  [GROUP.academicTitle]: {
    state: (state) => state.listOfValues.academicTitles,
    initialState: (state, value) => (state.academicTitles = value),
  },
    [GROUP.academicDiscipline]: {
      state: (state) => state.listOfValues.academicDisciplines,
      initialState: (state, value) => (state.academicDisciplines = value),
    },
  [GROUP.researchProject]: {
    state: (state) => state.listOfValues.researchProjects,
    initialState: (state, value) => (state.researchProjects = value),
  },
  [GROUP.researchCountry]: {
    state: (state) => state.listOfValues.researchCountries,
    initialState: (state, value) => (state.researchCountries = value),
  },
  [GROUP.workingLanguage]: {
    state: (state) => state.listOfValues.workingLanguages,
    initialState: (state, value) => (state.workingLanguages = value),
  },

};
