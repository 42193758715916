import {navigate} from "gatsby-plugin-intl";
import {LOGOUT_URL} from "../utils/constants/constants";
import {WSInstance} from "./api/axios_config";
import {getToken} from "./api/requests";
import jwt_decode from "jwt-decode";

export const isBrowser = () => typeof window !== "undefined";

const USER_KEY = "civica-user";
const JWT_KEY = `token`;

export const getUser = () => {
  if (isBrowser() && window.localStorage.getItem(USER_KEY)) {
    try {
      return JSON.parse(window.localStorage.getItem(USER_KEY));
    } catch (err) {}
  }
  return {};
};

export const login = async () => {
  if (!isBrowser) {
    return;
  }
  if (!isLoggedIn()) {
    try {
      await setToken();
    } catch (error) {
      navigate(`/`);
    }
  }
  navigate(`/`);
};

export const isLoggedIn = () => {
  if (!isBrowser()) return false;
  const token = window.localStorage.getItem("token");
  if (!!token) {
    let isExpired = false;
  const decodedToken = jwt_decode(token, { complete: true });
    const dateNow = new Date();
    if (decodedToken.exp * 1000 < dateNow.getTime()) {
      isExpired = true;
    }
    return !isExpired && !!decodedToken.displayName;
  } else {
    return false;
  }
};

export const logout = () => {
  try {
    window.localStorage.removeItem("token");
    WSInstance.get(LOGOUT_URL, { withCredentials: true });
    navigate(`/login`);
  } catch (error) {
    console.error("logout - ERROR: ", error);
    console.log(error);
  }
};

export const setToken = async () => {
  try {
    let data = await getToken();
    window.localStorage.setItem("token", data.data["token"]);
  } catch (error) {
    console.error("getToken - ERROR: ", error);
    throw error;
  }
};

export const getTokenDetails = () => {
  if (typeof window === `undefined`) return {};
  const token = window.localStorage.getItem("token");
  if (!!token) {
    return jwt_decode(window.localStorage.getItem("token"));
  }
};

export const getDisplayName = () => {
  const tokenDetails = getTokenDetails();
  return !!tokenDetails ? tokenDetails.displayName : "";
};

export function currentToken() {
  if (typeof window === `undefined`) return undefined;
  return localStorage.getItem(JWT_KEY);
}

export const getUserRole = () => {
  // mock set token to be deleted
  let token = currentToken();
  return token ? jwt_decode(token).authorities : [];
};

export const containsRole = (roles) => {
  let containsRole = false;
  if (roles) {
    const userRoles = getUserRole();
    roles.forEach((role, index) => {
      if (userRoles.indexOf(role) !== -1) {
        containsRole = true;
        return false;
      }
    });
  }
  return containsRole;
};

export const getAcademicId = () => {
  // mock set token to be deleted
  let token = currentToken();
  return token ? jwt_decode(token).academicId : [];
};

export const getUniversityId = () => {
  let token = currentToken();
  return token ? jwt_decode(token).universityId : [];
};