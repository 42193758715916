/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import store from "./src/state/ReduxWrapper";
import "./src/styles/global.scss"
import { Provider } from 'react-redux';
import React from 'react';

// export const registerServiceWorker = () => true;
// export const onServiceWorkerUpdateReady= () => window.location.reload(true);
export const onServiceWorkerUpdateReady = () => {
    const registerServiceWorker = true;
  
    if (registerServiceWorker === true) {
      window.location.reload()
    }
  }

export const wrapRootElement = ({ element }) => {
    return <Provider store={store}>{element}</Provider>
}