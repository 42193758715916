import {WSInstance} from "./axios_config";

const ALERTS_API_BASE_URL = `${process.env.API_URL}/alerts`;
const NEWS_API_BASE_URL = `${process.env.API_URL}/news`;
const HOME_API_BASE_URL = `${process.env.API_URL}/home`;
const OPPORTUNITY_API_BASE_URL = `${process.env.API_URL}/opportunities`;

export async function getAlerts() {
  try {
    return await WSInstance.get(ALERTS_API_BASE_URL, { withCredentials: true });
  } catch (error) {
    throw error;
  }
}
export async function getNews() {
  try {
    return await WSInstance.get(NEWS_API_BASE_URL, { withCredentials: true });
  } catch (error) {
    throw error;
  }
}
export async function getHome() {
  try {
    return await WSInstance.get(HOME_API_BASE_URL, { withCredentials: true });
  } catch (error) {
    throw error;
  }
}
export async function getNewsById(newsId) {
  try {
    return await WSInstance.get(`${NEWS_API_BASE_URL}/${newsId}`,{withCredentials: true});

  } catch (error) {
    throw error;
  }
}

const saveOpportunity = async (param, editContent) => {
  let formData = new FormData();
  if (param.coverImage) {
    formData.append("coverImage", param.coverImage[0]);
  }
//  delete param.coverImage;
  param.content = editContent;
  formData.append("opportunity", JSON.stringify(param));
  return await WSInstance.post(`${OPPORTUNITY_API_BASE_URL}`, formData, {
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateOpportunity = async (id, param, editContent) => {
  let formData = new FormData();
  if (param.coverImage) {
    formData.append("coverImage", param.coverImage[0]);
    param.deleteImage = false;
  }
  // delete param.coverImage;
  param.content = editContent;
  param.id = id;
  formData.append("opportunity", JSON.stringify(param));
  return WSInstance.post(`${OPPORTUNITY_API_BASE_URL}/update`, formData, {
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const searchByCriteria = async (formData, page, pageSize, orderModel) => {
  formData["sort"] = [];
  if (!!orderModel) {
    orderModel.forEach((element) => {
      formData["sort"].push(element.field + "," + element.sort);
    });
  }
  formData["page"] = page;
  formData["size"] = pageSize;

  if (formData["visible"] && formData["visible"].length === 2) {
    delete formData["visible"];
  }
  return await WSInstance.get(OPPORTUNITY_API_BASE_URL, { params: formData });
};

const deleteOpportunities = async (opportunities) => {
  return WSInstance.post(`${OPPORTUNITY_API_BASE_URL}/delete`, opportunities, {
    withCredentials: true,
  });
};

const searchById = async (oppoortunityId) => {
  return await WSInstance.get(`${OPPORTUNITY_API_BASE_URL}/${oppoortunityId}`);
};

const getImage = async (fileId) => {
  return await WSInstance.get(`${process.env.API_URL}/media/${fileId}`);
};

const newsService = {
  getAlerts,
  saveOpportunity,
  deleteOpportunities,
  searchByCriteria,
  updateOpportunity,
  searchById,
  getImage
};

export default newsService;
