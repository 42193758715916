export const GROUP = {
  university: "UNIVERSITY",
  logo:"LOGO",
  universityAll: "UNIVERSITY_ALL",
  universityAllWithAcademic: "UNIVERSITY_ALL_WITH_ACADEMIC",
  universityUnit: "UNIVERSITY_UNIT",
  courseType: "COURSE_TYPE",
  courseSubtype: "COURSE_SUBTYPE",
  courseCycle: "COURSE_CYCLE",
  courseTitle: "COURSE_TITLE",
  topic: "TOPIC",
  topicFull: "TOPIC_FULL",
  language: "LANGUAGE",
  period: "PERIOD",
  sessionFormat: "SESSION_FORMAT",
  linksType: "LINK_TYPE",
  title: "TITLE",
  gender: "GENDER",
  teacher: "TEACHER",
  teacherVisible: "TEACHER_VISIBLE",
  opportunityType: "OPPORTUNITY_TYPE",
  keyword: "KEYWORD",
  userRoleGroupName: "USER_ROLE_GROUP_NAME",
  roleGroup: "ROLE_GROUP",
  role: "ROLE",
  researchCenterUniversity: "RESEARCH_CENTER_UNIVERSITY",
  researchCenterUniversityWithAcademic: "RESEARCH_CENTER_UNIVERSITY_WITH_ACADEMIC",
  expertiseField: "EXPERTISE_FIELD",
  expertiseFieldWithAcademic: "EXPERTISE_FIELD_WITH_ACADEMIC",
  disciplineFieldWithAcademic: "DISCIPLINE_FIELD_WITH_ACADEMIC",
  countriesResearchFieldWithAcademic: "COUNTRIES_RESEARCH_FIELD_WITH_ACADEMIC",
  workingLanguageFieldWithAcademic: "WORKING_LANGUAGE_FIELD_WITH_ACADEMIC",
  academicTitle: "ACADEMIC_TITLE",
  academicDiscipline: "ACADEMIC_DISCIPLINE",
  workingLanguage: "WORKING_LANGUAGE",
  researchProject: "RESEARCH_PROJECT",
  researchCountry: "RESEARCH_COUNTRY",
};

export const COURSE_TYPE = {
  open: "OPEN",
  joint: "JOINT",
  multi: "MULTI",
  skills: "SKILLS",
  additional: "ADDITIONAL",
  other: "OTHER",
};

export const OPPORTUNITY_TYPE = {
  alert: "ALERT",
  home: "HOME",
  news: "NEWS"
};


export const PUBLIC_PROFILE = {
  student: "STUDENT",
};
